
<div class="account-pages mt-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-8 col-lg-12">
                <div class="card bg-pattern">
                    <div class="card-body p-4" style="box-shadow:2px 2px 5px 5px #dcd9d966">
                        <!-- Logo -->
                        <div class="text-center w-75 m-auto">
                            <a>
                                <img src="assets/images/logo.svg" alt="" style="width: 153px;">
                            </a>
                            <p class="text-muted mb-2 mt-2">Enter your Name and password to access admin panel For Feedback</p>
                        </div>

                     

                            <div id ='main_error' class="alert alert-danger" style="display:none">{{ error }}</div>

                            <div class="form-group mb-2">
                                <label for="name">Name</label>

                                <input type="name" formControlName="name" #name class="form-control" class="form-control" id="name" placeholder="Name" />
               
                                <span id="error" style="color:red"></span>
                            </div>

                            <div class="form-group mb-2">
                                <label for="password">Password</label>

                                <input type="password" #pass formControlName="password" class="form-control" class="form-control" id="password" placeholder="Password" />

                                <span id="error2"  style="color:red"></span>
                            </div>

                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary btn-block" [disabled]="loading" (click)="onSubmit(name,pass)">Log In</button>
                            </div>
                        

                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <!--  <p>
                            <a routerLink="/auth/reset-password" style="color:orange" class="ml-1">Forgot your password?</a>
                        </p>-->

                      <!--  <p style="color:rgba(255, 166, 0, 0.966)">Don't have an account? <a routerLink="/auth/register" class="ml-1"><b>Sign
                Up</b></a>
                        </p>-->
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->

            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>


<!-- end page -->

<footer class="footer footer-alt" style="text-align: center;">
    2023 &copy; Powered by <a href="https://feedbk.online/" style="color:red"><b>Feedback</b></a>
</footer>

